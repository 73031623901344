<template>
  <edit-template @confirm="handleSubmit" :confirmLoading="loading">
    <el-form ref="form" :model="formData" :rules="rules" label-width="150rem">
      <el-form-item label="学生姓名" prop="name">
        <el-input v-model="formData.name"  placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="学生身份证号" prop="idCard">
        <el-input v-model="formData.idCard"  placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="学生参考城市" prop="city">
        <el-select v-model="formData.city" placeholder="请选择" @change="handleCityChange">
          <el-option v-for="item in cityData" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div label="配置考试分数" class="singe-list z-no-margin-bottom allow">
      <el-form ref="dynamicForm" :model="dynamicData" :disabled="!formData.city">
        <el-table :data="dynamicData.tableData" border :stripe="false">
          <el-table-column :prop="item.prop" :label="item.label" v-for="item in dynamicTableData" :key="item.prop">
            <template #default="scope" v-if="!item.isNo">
              <el-form-item :prop="`tableData.${scope.$index}.${item.prop}`" :rules="dynamicNumber">
                <el-input v-model="scope.row[item.prop]" type="number" placeholder="请输入"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </div>
  </edit-template>
</template>

<script>
import { validateNumber } from '@/utils/validate'
export default {
	_config:{"route":[{"path":"edit-delete","meta":{"title":"编辑"}}, {"path":"add-delete","meta":{"title":"新增"}}]},
  data() {
    return {
      id: '',
      loading: false,
      dynamicData: { tableData: [
        { name: '', total: '', language: '', mathematics: '', english: '', physics: '', chemistry: ''},
        { name: '', total: '', language: '', mathematics: '', english: '', physics: '', chemistry: ''},
        { name: '', total: '', language: '', mathematics: '', english: '', physics: '', chemistry: ''},
      ] },
      dynamicTableData: [
        { prop: 'name', label: '考试名称', isNo: true },
        { prop: 'total', label: '考试总分' },
        { prop: 'language', label: '语文成绩' },
        { prop: 'mathematics', label: '数学成绩' },
        { prop: 'english', label: '英语成绩' },
        { prop: 'physics', label: '物理成绩' },
        { prop: 'chemistry', label: '化学成绩' }
      ],
      dynamicNumber: { required: true, validator: (_, value, callback) => validateNumber(value, callback, { min: 0, max: 10000, equalToMin: true, equalToMax: true }), trigger: 'blur' },
      formData: { name: '', idCard: '', city: '', domains: [] },
      rules: {
        name: [{ required: true, message: '请输入', trigger: 'change' }],
        idCard: [{ required: true, message: '请输入', trigger: 'change' }],
        city: [{ required: true, message: '请输入', trigger: 'change' }],
      },
      cityData: [],
    }
  },
  created() {
    if(this.$route.query.id) {
      this.id = +this.$route.query.id
    }
    this.getConfig()
    this.getData()
  },
  methods: {
    getConfig() {
      this.cityData = [
        { label: '成都', value: 1 },
        { label: '资阳', value: 2 },
        { label: '达州', value: 3 },
        { label: '大邑', value: 4 },
        { label: '德阳', value: 5 },
      ]
    },
    getData() {},
    handleCityChange(val) {
      const idx = this.cityData.findIndex(item => item.value === val)
      const name = this.cityData[idx].label
      const arr = ['一诊', '二诊', '三诊']
      if(~idx) {
        this.dynamicData.tableData.forEach((item, i) => {
          item.name = name + arr[i]
        })
      }
    },
    handleSubmit() {
      this.$refs['form'].validate(valid => {
        if(valid) {
          this.$refs['dynamicForm'].validate(valid2 => {
            if(valid2) {
              this.loading = true
              setTimeout(() => {
                this.$message.success(`${this.id ? '编辑' : '新增'}成功`)
                this.loading = false
              }, 700)
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.singe-list {
  ::v-deep {
    .el-form-item__label {
      width: 100% !important;
      margin-bottom: 20rem;
      text-align: left;
      font-size: 20rem;
      font-weight: 600;
    }
    .el-form-item__content {
      margin-left: 0 !important;
    }
  }
}
</style>
